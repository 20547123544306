import React from "react";
import {
  Box,
  Typography,
  Grid,
  Stack,
  Card,
  ButtonBase,
  Divider,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import {
  Add as AddIcon,
  Remove as RemoveIcon,
  DeleteForever as DeleteForeverIcon,
} from "@mui/icons-material";
import RenderIngredients from "../Products/RenderIngredients";
import { safeRound } from "../Functions/FormatValues";
import { GetNameByLanguage } from "../Functions/LanguageFunctions";
import ProductImage from "../Cards/ProductImage";

function QuantityButtons({
  quantity,
  product_price,
  product_currency,
  removeItem,
  addItem,
  productData,
  disableOnRemove,
  disableOnAdd,
}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        height: "30px",
        boxShadow: 1,
        borderRadius: "5px",
      }}
    >
      <ButtonBase
        variant="contained"
        disabled={disableOnRemove}
        sx={{
          borderRadius: "5px",
          color: disableOnRemove ? "#b3b3b3" : "white",
          backgroundColor: disableOnRemove
            ? "grey"
            : theme.palette.primary.dark,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
        onClick={() => removeItem(productData)}
      >
        {quantity > 1 ? <RemoveIcon /> : <DeleteForeverIcon />}
      </ButtonBase>

      <Typography
        align="center"
        variant="body2"
        sx={{ whiteSpace: "no-wrap", padding: "5px" }}
      >
        {quantity}
      </Typography>
      <ButtonBase
        variant="contained"
        disabled={disableOnAdd}
        sx={{
          borderRadius: "5px",
          backgroundColor: disableOnAdd ? "grey" : theme.palette.primary.dark,
          color: disableOnAdd ? "#b3b3b3" : "white",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
        onClick={() => addItem(productData)}
      >
        <AddIcon />
      </ButtonBase>
    </Box>
  );
}

function CardProduct({ productData, removeItem, addItem }) {
  console.log("productData:", productData);
  return (
    <Box>
      <Stack
        direction="row"
        spacing={1}
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Stack direction="row" spacing={1}>
          <ProductImage
            local_id={productData.local_id}
            product_name={productData.product_name}
            product_cover={productData.product_cover}
            style={{
              width: "60px",
              height: "60px",
              borderRadius: "5px",
            }}
          />

          <Box>
            <Stack spacing={1} direction="row">
              <Typography
                color="text.paper"
                component="div"
                variant="subtitle1"
                sx={{ fontWeight: 600 }}
              >
                {GetNameByLanguage(productData.product_name)}
              </Typography>
            </Stack>
            {productData?.productFormatSelected?.label && (
              <li>
                <Typography
                  color="text.paper"
                  style={{ fontSize: "14px" }}
                  component="span"
                  variant="body1"
                >
                  {productData.productFormatSelected.icon}{" "}
                  {productData.productFormatSelected.label}{" "}
                  {productData.productFormatSelected.price}€
                </Typography>
              </li>
            )}
            <RenderIngredients
              productIngredientsVar={productData.productIngredientsVar}
              product_currency={productData.product_currency}
            />

            {productData.product_message !== "" && (
              <Typography
                color="text.paper"
                style={{ fontSize: "14px" }}
                component="body"
                variant="body1"
              >
                <span style={{ fontWeight: "bold" }}>Messaggio:</span>
                {productData.product_message}
              </Typography>
            )}
          </Box>
        </Stack>
        <Box item xs={3} sm={2} md={1} lg={1} style={{ alignSelf: "center" }}>
          <Stack
            spacing={1}
            direction="column"
            sx={{
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <QuantityButtons
              quantity={productData.quantity}
              product_price={safeRound(productData.product_price)}
              product_currency={productData.product_currency}
              removeItem={removeItem}
              addItem={addItem}
              productData={productData}
              disableOnRemove={false}
              disableOnAdd={false}
            />
            <Typography
              color="text.paper"
              style={{ float: "right", whiteSpace: "nowrap" }}
              component="h5"
              variant="h5"
            >
              {safeRound(productData.product_price * productData.quantity) +
                " " +
                productData.product_currency}
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}

function OrderRecapDetail(props) {
  const { local_id, basket, onChangeBasket, ...rest } = props;

  function removeItem(item) {
    var index = -1;
    for (const itemBasket of basket[local_id]) {
      index += 1;
      if (JSON.stringify(itemBasket) === JSON.stringify(item)) {
        if (basket[local_id][index]["quantity"] > 1) {
          // se la quantità è maggiore di uno, riduci la quantità di uno
          basket[local_id][index]["quantity"] -= 1;
        } else {
          // altrimenti, se la quantità è uno, rimuovi l'elemento
          basket[local_id].splice(index, 1);
        }
        break;
      }
    }
    onChangeBasket({ ...basket });
  }

  function addItem(item) {
    var index = -1;
    for (const itemBasket of basket[local_id]) {
      index += 1;
      if (JSON.stringify(itemBasket) === JSON.stringify(item)) {
        basket[local_id][index]["quantity"] += 1;
        break;
      }
    }
    onChangeBasket({ ...basket });
  }

  return (
    <Stack
      {...rest}
      spacing={1}
      divider={<Divider orientation="horizontal" flexItem />}
    >
      {basket[local_id] &&
        basket[local_id].map(
          (productData, index) =>
            productData.quantity !== undefined && (
              <CardProduct
                key={index}
                productData={productData}
                removeItem={removeItem}
                addItem={addItem}
              />
            )
        )}
    </Stack>
  );
}

export default OrderRecapDetail;

OrderRecapDetail.propTypes = {
  local_id: PropTypes.string.isRequired,
  basket: PropTypes.array.isRequired,
  onChangeBasket: PropTypes.func.isRequired,
};
