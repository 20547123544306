import { useEffect, useRef, useState } from "react";
import Ably from "ably";

let ablyInstance = null; // Singleton globale per la connessione Ably
let subscriptions = {}; // Oggetto per gestire le sottoscrizioni per canale

const pubSubService = (channelName, callback) => {
  const [connectionState, setConnectionState] = useState("connecting");
  const [token, setToken] = useState(localStorage.getItem("token")); // Stato per il token
  const callbackRef = useRef(callback); // Memorizza il callback per evitare problemi di riferimento
  const checkConnectionIntervalRef = useRef(null); // Ref per il controllo periodico

  useEffect(() => {
    callbackRef.current = callback; // Aggiorna il riferimento al callback ad ogni render
  });

  // Monitorare i cambiamenti nel localStorage
  useEffect(() => {
    const handleStorageChange = () => {
      setToken(localStorage.getItem("token")); // Aggiorna lo stato con il nuovo token
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const prefixedChannelName = `${process.env.REACT_APP_ENV}_${channelName}`;
    console.log("Prefixed channel:", prefixedChannelName);

    const initializeAbly = async () => {
      if (!token) {
        console.log("No token available, skipping connection.");
        return;
      }

      if (!ablyInstance) {
        try {
          ablyInstance = new Ably.Realtime({
            authUrl: `/socketio-auth`,
            authHeaders: {
              Authentication: token,
            },
          });

          ablyInstance.connection.on((stateChange) => {
            console.log("Ably connection state changed:", stateChange.current);
            setConnectionState(stateChange.current);
          });
        } catch (error) {
          console.error("Error initializing Ably:", error);
          return;
        }
      }

      // Controlla se esiste già una sottoscrizione per questo canale
      if (!subscriptions[prefixedChannelName]) {
        const channel = ablyInstance.channels.get(prefixedChannelName);

        // Assicurati di evitare duplicati rimuovendo eventuali listener esistenti
        channel.unsubscribe();

        const subscription = channel.subscribe((message) => {
          console.log(`Message received on ${prefixedChannelName}:`, message);
          if (callbackRef.current) {
            callbackRef.current(message.data);
          }
        });

        subscriptions[prefixedChannelName] = subscription;
        console.log(`Subscribed to channel: ${prefixedChannelName}`);
      }
    };

    const cleanupAbly = () => {
      if (subscriptions[prefixedChannelName]) {
        const channel = ablyInstance.channels.get(prefixedChannelName);
        channel.unsubscribe(subscriptions[prefixedChannelName]);
        delete subscriptions[prefixedChannelName];
        console.log(`Unsubscribed from channel: ${prefixedChannelName}`);
      }

      if (ablyInstance && !token) {
        ablyInstance.close();
        ablyInstance = null;
        console.log("Ably connection closed due to missing token.");
      }
    };

    const checkConnection = () => {
      if (ablyInstance && ablyInstance.connection.state !== "connected") {
        console.warn("Ably connection lost. Attempting to reconnect...");
        cleanupAbly();
        initializeAbly();
      } else {
        console.log("Ably connection status:", ablyInstance.connection.state);
      }
    };

    initializeAbly();

    // Avvia un intervallo per controllare lo stato della connessione
    checkConnectionIntervalRef.current = setInterval(checkConnection, 5000); // Ogni 5 secondi

    return () => {
      cleanupAbly();
      clearInterval(checkConnectionIntervalRef.current); // Ferma il controllo periodico
    };
  }, [channelName, token]); // Ritriggera quando il token cambia o il canale cambia

  return connectionState;
};

export default pubSubService;
